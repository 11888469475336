@import 'colors';

.gjs-block-label,
.gjs-sm-header,
.gjs-trt-header {
  font-size: 14px;
}

/* Hide file uploader in image selector */
.gjs-am-file-uploader {
  display: none;
}

.gjs-am-assets-cont {
  width: 100%;
  float: none;
}

.gjs-am-preview-cont {
  height: 100px;
}

.gjs-am-asset:hover .gjs-am-close {
  display: none;
}

.gjs-block.fa {
  font-size: 4em;
}
