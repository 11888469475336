@primary-color: #f15a24;
@accent-color: #0870A6;
@ant-blue-color: #e6f7ff;
@black-color: #000;
@white-color: #fff;
@red-color: #ff4d4f;
@green-color: green;
@grey-color: #f8f8f8;
@dark-grey-color-1: #969696;
@dark-grey-color-2: #828282;
@grey-color-2: #e6e6e6;

@accent-tint-1: #0786b5;
@accent-tint-2: #088ebf;
@accent-tint-3: #089bcc;
@accent-tint-4: #0893c2;


[red] {
  color: @red-color;
}

[green] {
  color: @green-color;
}

@tagline-description-color: #24272d;

@digital-filing-font-color: #ff931e;
@digital-filing-color: #ffbe00;
@digital-filing-bg-color: rgba(255, 190, 0, 0.28);

@transactional-email-color: #29abe2;
@transactional-email-bg-color: rgba(41, 171, 226, 0.28);

@email-marketing-font-color: #018c8b;
@email-marketing-color: #00a99d;
@email-marketing-bg-color: rgba(0, 169, 157, 0.28);
