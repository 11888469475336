@import 'colors';

.anticon svg {
  vertical-align: baseline;
}

.ant-menu-item .anticon + span,
.ant-menu-submenu-title .anticon + span {
  margin-left: 16px;
}

[sub-menu-item] {
  margin-left: 8px;
}

.ant-layout-sider .ant-menu {
  font-size: 16px;
}

.ant-layout-sider .ant-menu .ant-menu-item {
  margin-bottom: 10px;
}

.ant-descriptions-item-label {
  width: 25%;
}

.ant-descriptions-item-content {
  width: 25%;
}

.ant-breadcrumb a {
  font-size: 16px;
  color: rgba(0, 0, 0, 0.85);
}

.ant-progress-circle .ant-progress-text{
  white-space: pre-line;
  line-height: 2rem;
}

.type-select.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-color: #f0f0f0;
}

.activity-type-select.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-color: #f0f0f0;
}

.type-select .ant-select-selection-placeholder,
.activity-type-select .ant-select-selection-placeholder {
  color: black;
}

a h4.ant-typography {
  color: @primary-color;
}

[collapse-table] .ant-collapse-content > .ant-collapse-content-box {
  padding: 0;
}

[collapse-table] table {
  border: 0;
}

[supported-types-label] {
  max-width: 50%;
  flex: inherit;
}

[supported-types-select] {
  max-width: 100%;
}

.ant-descriptions-item-label {
  vertical-align: top;
}

.ant-descriptions-view [preview] table {
  width: auto;
}

[image-avatar].ant-avatar > img {
  object-fit: contain;
}

.ant-modal-confirm-content {
  white-space: pre-wrap;
}

.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after,
.ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
  background-color: @dark-grey-color-2;
}

.text-green-600.anticon {
  color: rgb(22 163 74);
}

.ant-checkbox-group.flex {
  display: flex;
}

nz-textarea-count {
  width: 100%;
}
